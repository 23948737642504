import {
  all,
  call,
  put,
  takeEvery,
  select,
  delay,
  take,
  race,
} from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';
import { navigate } from 'gatsby';

import {
  types,
  startBankLoginSessionSuccess,
  startBankLoginSessionFailure,
  startBankLoginPolling,
  updateBankLoginSession,
  retrieveBankData,
  retrieveBankDataSuccess,
  retrieveBankDataFailure,
  bankReset,
  restoreBankLoginSession,
  bankAuthenticationSuccess,
  bankAuthenticationFailure,
  bankLoginSessionReset,
} from '@redux/actions/bank';
import { types as typesUser, fetchUser } from '@redux/actions/user';
import {
  startBankLoginSession,
  checkBankLoginSession,
  retrieveBankData as retrieveBankDataCall,
  fetchBankAuthenticationLink,
} from '@apiClient/bank';
import { types as typesSignup } from '@redux/actions/signup';
import { invalidateCachedRequest } from '@redux/actions/cache';
import { cacheKeys } from '@redux/sagas/cache';
import { types as typesAuth } from '@redux/actions/auth';
import modalTypes from '@components/modals/types';
import { hideModal, showModal } from '@redux/actions/ui';
import bankLoginStatuses from '@constants/bankLoginStatuses';
import urls from '@constants/urls';
import bankLoginActions from '@constants/bankLoginActions';
import tinkErrors from '@constants/tinkErrors';
import getUrl from '@utils/getUrl';
import { bankIdTypes, bankIdRequests } from '@constants/bankId';
import cancellableTakeLatest from '@utils/cancellableTakeLatest';
import helpTexts from '@constants/helpTexts';

const getLoginSession = (state) => state.bank.loginSession;
const getSignup = (state) => state.signup;
const getTracking = (state) => state.tracking;
const getProduct = (state) => state.signup.product;
const getPartnerId = (state) => state.signup.partnerId;

const persistSelectorsMap = {
  [bankLoginActions.MANDATE]: {
    signup: getSignup,
    tracking: getTracking,
  },
};

const getSuccessRedirectionUrl = (parameters) => {
  if (!parameters) return '';

  switch (parameters.action) {
    case bankLoginActions.MANDATE: {
      const { accountId } = parameters;
      if (!accountId) throw new Error('Could not find any accountId for redirection to mandate flow');
      return getUrl(urls.HOME.AUTOGIRO.VALID_BANK_ACCOUNT, { accountId });
    }
    default:
      return '';
  }
};

const getFailureRedirectionUrl = (parameters) => {
  if (!parameters) return '';

  switch (parameters.action) {
    case bankLoginActions.MANDATE: {
      const { accountId } = parameters;
      if (!accountId) throw new Error('Could not find any accountId for redirection to mandate flow');
      return getUrl(urls.HOME.AUTOGIRO.CONNECT_BANK, { accountId });
    }
    default:
      return '';
  }
};

function* startBankLoginSessionSaga({ provider, parameters }) {
  const providers = yield select((state) => state.settings.constants.providers);

  if (provider === providers.TINK_LINK) {
    const persistSelectors = persistSelectorsMap[parameters.action];
    const data = {};
    for (let i = 0; i < Object.keys(persistSelectors).length; i += 1) {
      const [selectorName, selector] = Object.entries(persistSelectors)[i];
      const selectedData = yield select(selector);
      Object.assign(data, { [selectorName]: selectedData });
    }
    const { redirectLink, sessionId } = yield call(
      startBankLoginSession,
      provider,
      { ...parameters, data },
    );
    global.location = redirectLink;
    yield put(startBankLoginSessionSuccess({ sessionId }));
  } else if (provider === providers.OPEN_PAYMENTS) {
    const persistSelectors = persistSelectorsMap[parameters.action];
    // TODO: Refactor persist logic in function to use here and with tink
    const data = {};
    const persistSelectorKeys = persistSelectors
      ? Object.keys(persistSelectors) : [];
    for (let i = 0; i < persistSelectorKeys.length; i += 1) {
      const [selectorName, selector] = Object.entries(persistSelectors)[i];
      const selectedData = yield select(selector);
      Object.assign(data, { [selectorName]: selectedData });
    }

    try {
      const { scaApproach, sessionId } = yield call(
        startBankLoginSession,
        provider,
        { ...parameters, data },
      );
      let link;

      if (scaApproach === 'DECOUPLED') { // TODO: Use constants
        yield put(showModal({
          type: modalTypes.BANK_ID,
          props: {
            request: bankIdRequests.BANK_AUTHENTICATION,
            payload: { sessionId },
          },
        }));
      } else if (scaApproach === 'REDIRECT') {
        ({ link } = yield call(fetchBankAuthenticationLink, { sessionId }));
        global.location = link;
      } else {
        throw new Error(`Unhandled scaApproach ${scaApproach}`);
      }
      yield put(startBankLoginSessionSuccess({ scaApproach, sessionId, link }));
    } catch (e) {
      yield put(startBankLoginSessionFailure());
      yield put(showModal({ type: modalTypes.ERROR }));
    }
  } else if (provider === providers.UC) {
    const persistSelectors = persistSelectorsMap[parameters.action];
    const data = {};
    for (let i = 0; i < Object.keys(persistSelectors).length; i += 1) {
      const [selectorName, selector] = Object.entries(persistSelectors)[i];
      const selectedData = yield select(selector);
      Object.assign(data, { [selectorName]: selectedData });
    }
    const { consentLink, sessionId } = yield call(
      startBankLoginSession,
      provider,
      { ...parameters, data },
    );
    yield put(startBankLoginSessionSuccess({ sessionId }));
    global.location = consentLink;
  }
}

function* startBankLoginPollingSaga({ sessionId, sessionDataParameters }) {
  const loginSession = yield select(getLoginSession);
  const { provider } = loginSession;
  const product = yield select(getProduct);
  const partnerId = yield select(getPartnerId);
  const failureUrl = getFailureRedirectionUrl(
    sessionDataParameters,
    product,
    provider,
    partnerId,
  );
  try {
    const bankIdType = yield select((state) => state.auth.bankIdType);

    let image;
    let status;
    let message;
    let token;

    const isValidStatus = (bankLoginSessionStatus) => (
      bankLoginSessionStatus === bankLoginStatuses.SUCCESS
      || bankLoginSessionStatus === bankLoginStatuses.BANK_AUTHENTICATION_SUCCESS
      || bankLoginSessionStatus === bankLoginStatuses.FAILURE
    );

    const results = yield race({
      timeout: delay(30000),
      poll: call(function* pollSession() {
        while (!isValidStatus(status)) {
          ({
            image,
            bankLoginSession: {
              status,
              message,
              token,
            },
          } = yield call(checkBankLoginSession, sessionId, bankIdType));

          yield put(updateBankLoginSession(status, message, token, image));

          if (!isValidStatus(status)) yield delay(2000);
        }
      }),
    });

    if (status === bankLoginStatuses.SUCCESS) {
      yield put(retrieveBankData(provider, token));
    } else if (status === bankLoginStatuses.FAILURE || results.timeout) {
      if (failureUrl) navigate(failureUrl, { replace: true });
      yield put(showModal(modalTypes.BANK_LOGIN_FAILED));
      yield put(bankReset());
    }
  } catch (e) {
    if (failureUrl) navigate(failureUrl, { replace: true });
    yield put(showModal(modalTypes.BANK_LOGIN_FAILED));
    yield put(bankReset());
  }
}

function* bankAuthenticationSaga({ sessionId }) {
  try {
    yield put(hideModal());
    const bankIdType = yield select((state) => state.auth.bankIdType);
    if (!bankIdType) throw new Error('Missing required bankIdType');

    const bankAuthenticationData = yield call(
      fetchBankAuthenticationLink,
      { sessionId, bankIdType },
    );

    if (bankIdType === bankIdTypes.SAME_DEVICE) {
      if (!bankAuthenticationData.link) throw new Error('Missing required link for redirection');
      global.location = bankAuthenticationData.link;
    } else if (bankIdType === bankIdTypes.OTHER_DEVICE) {
      const {
        image,
        bankLoginSession: {
          status,
          message,
          token,
        },
      } = yield call(checkBankLoginSession, sessionId, bankIdType);
      yield put(updateBankLoginSession(status, message, token, image));
    }
    yield put(startBankLoginPolling(sessionId));

    yield put(bankAuthenticationSuccess(bankAuthenticationData));
  } catch (e) {
    yield put(showModal(modalTypes.ERROR));
    yield put(bankAuthenticationFailure());
  }
}

function* handleBankLoginRedirect({
  sessionId,
  code,
  message,
  error,
  errorReason,
  trackingId,
}) {
  try {
    if (!sessionId) throw new Error('Did not receive any state from provider');
    const providers = yield select((state) => state.settings.constants.providers);

    const reponseBankLoginSession = yield call(checkBankLoginSession, sessionId);
    const sessionData = reponseBankLoginSession.bankLoginSession;
    const { action } = sessionData.parameters;
    delete sessionData.data;

    if (!Object.values(bankLoginActions).includes(action)) {
      yield put(showModal({ type: modalTypes.ERROR }));
      Sentry.withScope((scope) => {
        scope.setExtra('action', action);
        Sentry.captureMessage('Invalid action for bank');
      });

      navigate(getUrl(urls.LANDING), { replace: true });
    } else {
      yield put(restoreBankLoginSession(sessionData));

      const loginSession = yield select(getLoginSession);
      const { provider } = loginSession;

      if (error || !code) {
        const cleanedMessage = message && message.replace(/.*message: /, '');
        yield put(updateBankLoginSession(bankLoginStatuses.FAILURE, cleanedMessage));

        if (error === tinkErrors.USER_CANCELLED) {
          // Nothing to do, this is a user initiated action
        } else if (error === tinkErrors.AUTHENTICATION_ERROR) {
          yield put(showModal({
            type: modalTypes.ERROR,
            props: {
              title: 'Legitimeringen misslyckades',
              message: `Något gick fel vid legitimeringen. Vi fick följande meddelande från din bank: ${cleanedMessage} Om du har problem med att ansluta din bank, kontakta vår kundtjänst.`,
            },
          }));
        } else if (error === tinkErrors.TEMPORARY_ERROR) {
          yield put(showModal({
            type: modalTypes.ERROR,
            props: {
              title: 'Tillfälligt fel',
              message: 'Ett tillfälligt fel uppstod med din bank eller vår partner Tink. Vänligen försök igen. Om problemet kvarstår, kontakta vår kundtjänst.',
            },
          }));
          Sentry.withScope((scope) => {
            scope.setExtra('message', message);
            scope.setExtra('sessionData', sessionData);
            scope.setExtra('trackingId', trackingId);
            Sentry.captureMessage(`Tink auth failed for ${sessionData?.parameters?.bank}: ${error} - ${errorReason}`);
          });
        } else {
          yield put(showModal({ type: modalTypes.ERROR }));
          Sentry.withScope((scope) => {
            scope.setExtra('message', message);
            scope.setExtra('sessionData', sessionData);
            scope.setExtra('trackingId', trackingId);
            Sentry.captureMessage(`Tink auth failed for ${sessionData?.parameters?.bank}: ${error} - ${errorReason}`);
          });
        }
        const product = yield select(getProduct);
        const partnerId = yield select(getPartnerId);
        const failureUrl = getFailureRedirectionUrl(
          sessionData.parameters,
          product,
          provider,
          partnerId,
        );
        navigate(failureUrl, { replace: true });
      } else if (provider === providers.UC) {
        yield put(startBankLoginPolling(sessionId, sessionData.parameters));
      } else {
        yield put(updateBankLoginSession(bankLoginStatuses.SUCCESS, '', code));
        yield put(retrieveBankData(provider, code));
      }
    }
  } catch (e) {
    yield put(showModal({ type: modalTypes.ERROR }));
    Sentry.withScope((scope) => {
      scope.setExtra('sessionId', sessionId);
      scope.setExtra('code', code);
      scope.setExtra('message', message);
      scope.setExtra('error', error);
      Sentry.captureException(e);
    });
  }
}

const retrieveDataErrorMessages = {
  INVALID_TOKEN: 'Anslutningen till din bank misslyckades. Vi ber om ursäkt för detta. Vänligen försök igen. Om problemet kvarstår, kontakta kundtjänst.',
  INVALID_CUSTOMER_ID: 'Det verkar som att länken är felaktig. Vi ber om ursäkt för detta. Vänligen kontakta kundtjänst för att få en ny länk.',
  INVALID_IDENTITY: 'Det verkar som att du försöker ansluta ett konto som inte tillhör dig. Vänligen försök igen med ett konto som tillhör dig.',
  INVALID_ACCOUNTS: 'Tyvärr kan vi inte hitta något giltigt konto hos banken du valde. Vänligen välj en annan bank för utbetalning och återbetalning av lånet.',
};

function* retrieveBankDataSaga({ provider, token }) {
  const { sessionId, parameters } = yield select(getLoginSession);
  const product = yield select(getProduct);
  const partnerId = yield select(getPartnerId);
  try {
    const data = {
      provider,
      token,
      parameters,
      sessionId,
    };
    const { accounts, reportId } = yield call(retrieveBankDataCall, data);
    yield put(retrieveBankDataSuccess(accounts, reportId));

    yield put(invalidateCachedRequest(cacheKeys.USER_GET_ME));
    const successUrl = getSuccessRedirectionUrl(parameters, product, provider, partnerId);
    if (successUrl) {
      if (parameters.action === bankLoginActions.MANDATE) {
        yield put(fetchUser());
        yield take(typesUser.FETCH_USER_SUCCESS);
        const bankAccounts = yield select((state) => state.user.bankDetails.bankAccounts);
        const signableBankAccounts = bankAccounts.filter(
          (bankAccount) => !bankAccount.isSigned && bankAccount.isValidForPayin,
        );

        yield put(showModal({
          type: modalTypes.INFO,
          props: {
            title: 'Din bank är nu anslutet till Moank.',
            helpText: signableBankAccounts.length === 0
              ? helpTexts.BANK_ACCOUNT_CONNECTION_NO_SIGNABLE
              : helpTexts.BANK_ACCOUNT_CONNECTION,
          },
        }));
      }
      navigate(successUrl, { replace: true });
    }
  } catch (e) {
    const message = (e.response && e.response.body && e.response.body.message) || '';
    yield put(showModal({
      type: modalTypes.ERROR,
      props: {
        title: 'Anslutningen misslyckades',
        message: retrieveDataErrorMessages[message],
      },
    }));
    if (![
      'INVALID_IDENTITY',
      'INVALID_TOKEN',
    ].includes(message)) {
      Sentry.withScope((scope) => {
        scope.setExtra('message', message || e.message);
        scope.setLevel(Sentry.Severity.Info);
        Sentry.captureMessage(`Retrieve bank data failed for ${parameters?.bank}: ${message || e.message}`);
      });
    }
    yield put(retrieveBankDataFailure());
    const failureUrl = getFailureRedirectionUrl(parameters, product, provider, partnerId);
    if (failureUrl) navigate(failureUrl, { replace: true });
    if (['INVALID_TOKEN', 'INVALID_IDENTITY', 'INVALID_ACCOUNTS'].includes(message)) {
      yield put(bankReset());
    }
  }
  yield put(bankLoginSessionReset());
}

function* updateFieldSaga({ field }) {
  if (field === 'socialSecurityNumber') yield put(bankReset());
  if (field === 'bank') yield put(bankReset());
}

function* bankResetSaga() {
  yield put(bankReset());
}

export default function* bankSaga() {
  yield all([
    cancellableTakeLatest(
      types.START_BANK_LOGIN_SESSION,
      types.BANK_RESET,
      startBankLoginSessionSaga,
    ),
    cancellableTakeLatest(
      types.START_BANK_LOGIN_POLLING,
      types.BANK_RESET,
      startBankLoginPollingSaga,
    ),
    takeEvery(types.HANDLE_BANK_LOGGIN_REDIRECT, handleBankLoginRedirect),
    cancellableTakeLatest(
      types.RETRIEVE_BANK_DATA,
      types.BANK_RESET,
      retrieveBankDataSaga,
    ),
    cancellableTakeLatest(
      types.BANK_AUTHENTICATION_REQUEST,
      types.BANK_RESET,
      bankAuthenticationSaga,
    ),
    takeEvery(typesSignup.UPDATE_FIELD, updateFieldSaga),
    takeEvery(typesAuth.LOGIN_SUCCESS, bankResetSaga),
  ]);
}
